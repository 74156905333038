.react-datepicker__header{
  height: 30px;
}

.react-datepicker-year-header {
  font-family: 'DM Sans';
  font-size: 12px;
  color: #2d2d65;
}

.react-datepicker__monthPicker{
  font-family: 'DM Sans';
  font-size: 10px;
}

.react-datepicker__month--in-range{
  background-color: #2d2d65CC;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, 
.react-datepicker__month-text.react-datepicker__month--in-range:hover, 
.react-datepicker__month-text.react-datepicker__quarter--selected:hover, 
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover, 
.react-datepicker__quarter-text.react-datepicker__month--selected:hover, 
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover, 
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, 
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover{
  background-color: #2d2d6580;
}